import { SegmentEventBase } from "@supernovaio/cloud/features/segment/types"

interface GenericErrorEventProps {
  failedAction: string
  domain: string
  errorInfo?: string
  errorText?: string
}

export function createErrorToastEvent({
  failedAction,
  domain,
  errorInfo,
  errorText,
}: GenericErrorEventProps): SegmentEventBase {
  return {
    eventName: "error_toast_displayed",
    failed_action: failedAction,
    domain,
    error_info: errorInfo,
    error_text: errorText,
  }
}

export function createGenericErrorEvent({
  failedAction,
  domain,
  errorInfo,
  errorText,
  location,
}: GenericErrorEventProps & { location: string }): SegmentEventBase {
  return {
    eventName: "generic_error_displayed",
    failed_action: failedAction,
    domain,
    error_info: errorInfo,
    error_text: errorText,
    location,
  }
}
