import { useCallback, useEffect, useMemo } from "react"

import { SupernovaError } from "@supernovaio/sdk"

import {
  useMutation,
  QueryClient,
  type DefaultError,
  type UseMutationOptions,
  type MutateOptions,
  type UseMutationResult,
} from "@tanstack/react-query"

import { useErrorHandler } from "./useErrorHandler"

type UseSafeMutationNewResult<TData, TError, TVariables, TContext> = Omit<
  UseMutationResult<TData, TError, TVariables, TContext>,
  "mutateAsync"
> & {
  mutateAsync: (
    values: TVariables,
    mutateOptions?: MutateOptions<TData, TError, TVariables, TContext>
  ) => Promise<TData | undefined>
}

type Options<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
> = UseMutationOptions<TData, TError, TVariables, TContext>

type CustomOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
> = {
  /**
   * Show error message in toast
   *
   * @default undefined
   */
  showToastOnError?: boolean

  /**
   * Run code after mutation either successed or errored.
   * The main differences between this function and onSettled is that this one won't have stale references and will be called later on.
   * So it's more suitable to handle eg. side effects like redirects
   */
  onAfterMutation?: (
    data: TData | undefined,
    error: TError | null,
    variables: TVariables,
    context: TContext | undefined
  ) => unknown
}

const defaultOptions: Partial<CustomOptions> = {
  showToastOnError: true,
}

/**
 * @description Custom hook based on `useMutation` with few additional options and error handling.
 */
export function useSafeMutation<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(
  inputOptions: Options<TData, TError, TVariables, TContext> &
    CustomOptions<TData, TError, TVariables, TContext>,
  queryClient?: QueryClient
): UseSafeMutationNewResult<TData, TError, TVariables, TContext> {
  const options = useMemo(
    () => ({
      ...defaultOptions,
      ...inputOptions,
    }),
    [inputOptions]
  ) as typeof inputOptions

  const { handleMutationError } = useErrorHandler()
  const mutation = useMutation<TData, TError, TVariables, TContext>(
    {
      ...options,
      onError(...args) {
        options.onError?.(...args)

        if (args[0] instanceof Error) {
          if (options.showToastOnError) {
            handleMutationError(args[0])
          }
        }
      },
    },
    queryClient
  )

  const { mutateAsync } = mutation

  useEffect(() => {
    if ((mutation.isSuccess || mutation.isError) && options.onAfterMutation) {
      options.onAfterMutation(
        mutation.data,
        mutation.error,
        mutation.variables,
        mutation.context
      )
    }
  }, [mutation, options])

  const safeMutateAsync = useCallback(
    async (
      values: TVariables,
      mutateOptions?: MutateOptions<TData, TError, TVariables, TContext>
    ) => {
      try {
        return await mutateAsync(values, mutateOptions)
      } catch (error) {
        if (error instanceof SupernovaError) {
          // This will catch any `SupernovaError` thrown by `mutateAsync`, preventing it from bubbling up.
          // The error is already handled by `onError`, so we don't need to do anything here.

          return undefined
        }

        // All other errors are not handled by this hook and will be rethrown.
        throw error
      }
    },
    [mutateAsync]
  )

  return { ...mutation, mutateAsync: safeMutateAsync }
}
